<!-- Cart.vue -->
<template>
    <div>

        <h3>Servicio(s)</h3>
        
        <div class="table-responsive">
        <table class="table table-striped" style="width: 100%">
            <tbody>
                <tr v-for="(item,i) in cartItems" :key="i">
                    <td>{{ i+1 }}</td>
                    <td><img :src="item.img" style="width:3rem" alt="" srcset=""></td>
                    <td>{{ item.name }}</td>
                    <td>${{ item.price }}</td>
                    <td><button class=" btn btn-secondary" v-on:click="removeFromCart(item)">Eliminar</button></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td><h4>Total:</h4></td>
                    <td><h4> <b>${{ cartTotalPrice }}</b></h4></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        </div> 
        
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
    setup() {
        const store = useStore();

        const cartItems = computed(() => store.state.cartItems);
        const cartTotalPrice = computed(() => store.getters.cartTotalPrice);

        const removeFromCart = (itemId) => {
            store.commit("removeFromCart", itemId);
        };

        return { cartItems, cartTotalPrice, removeFromCart };
    },
};
</script>

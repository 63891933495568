<template>
    <div>
        <h1>Servicios</h1>
        <br />
        <div class="row">
            <div class="col-md-4" v-for="(item, i) in data" :key="i">
                <div class="services-card">
                    <div class="service">
                        <img
                            style="width: 100%;"
                            
                            :src="item.img"
                            alt=""
                            srcset=""
                        />
                        <div class="service-card-body">
                            <h3 class="text-center"><b>{{ item.name }}</b></h3>
                            <div v-for="(e, i) in item.description" :key="i" class="container">
                                <div v-if="e != ''">
                                    <i class="fa fa-check"></i> {{ e }}
                                </div>
                                <div v-else><br /></div>
                            </div>

                            <h2 class="text-center">${{ item.price }}</h2>

                            <div class="text-center">
                            <router-link
                                class="btn btn-secondary align-content-center"
                                to="/agendar_cita"
                                v-on:click="
                                    addToCart({
                                        id: item.id,
                                        img: item.img,
                                        name: item.name,
                                        price: item.price,
                                    })
                                "
                                >Agregar Servicio</router-link
                            >
                            </div>
                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div>
        <b>Plachado</b>
        <p>
            Mediante el uso de la formadora industrial le regresamos la estructura a tus gorras como nuevas.
        </p>
                <b>Lavado y Plachado</b>
        <p>
            Es nuestro servicio estrella, nos encargamos de limpiar yvolver a darle la forma a tu gorra.
        </p>
                <b>Restaurado</b>
        <p>
            Si la gorra es un arapo, te recomendamos hacer esto: Te lavamos, te planchamos y pintamos tu gorra en los siguientes colores: Negro, Rojo y Azúl.
        </p>
        <b>Todos nustros trabajos al fina se le da una capa de repelente a las gorras para que duren mas tus servicios.</b>
    </div>
    <br />
</template>

<script>
//import { computed } from "vue";
import { useStore } from "vuex";
import services from "../services.js";

export default {
    data() {
        return {
            data: services,
        };
    },
    setup() {
        const store = useStore();

        const addToCart = (item) => {
            store.commit("addToCart", item);
        };

        return { addToCart };
    },
    methods: {
        subServices(array) {
            array.forEach((e) => {
                if (e > 0) {
                    return "Disponible";
                } else {
                    return "No";
                }
            });
        },
    },
};
</script>

<style scoped>
.services-card {
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}
.service-card-body {
    background-color: #f2f2f2;
    padding: 20px;
}
.service {
    margin-bottom: 10px;
}

.service h3 {
    font-size: 18px;
}


</style>

<template>
    <div>
        <div style="background-color: #222; padding: 0px 0px 10px 0px">
            <br>
            <div class="container">
                <router-link to="/">
                    <img
                        src="../src/img/logo_v2.jpg"
                        class="logo"
                        style="width: 100px"
                        :alt="siteData.title"
                        srcset=""
                    />
                </router-link>
                

            </div>
        </div>
        <div class="topnav" id="myTopnav">
                
            <div class="container">
                <router-link to="/" class="active" >{{ siteData.title }}</router-link>
                <router-link to="/servicios" v-on:click="myFunction()">Servicios</router-link>
                <router-link to="/acerca_de" v-on:click="myFunction()">Acerca de</router-link>
                <a href="/videos" v-on:click="myFunction()">Videos</a>
                
                <!--<div class="dropdown">
                <button class="dropbtn">
                    Dropdown
                    <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                    <a href="#">Link 1</a>
                    <a href="#">Link 2</a>
                    <a href="#">Link 3</a>
                </div>
              </div>-->
                <a
                    href="javascript:void(0);"
                    class="icon"
                    v-on:click="myFunction()"
                >
                    
                    <div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>
                </a>
            </div>
                        <div class="container">
                <router-link
                    to="/agendar_cita"
                    id="cart"
                    class="float-right text-white"
                >
                    <i class="fa fa-shopping-cart"></i> {{ count }}
                </router-link>    
            </div>
        </div>
    </div>
    <div class="text-right">
        <a
            href="https://wa.me/525533138568?text=Hola!%20Podemos%20agendar%20una%20cita?"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                src="../src/img/wa.png"
                alt="Watsapp"
                class="wa wa-scale"
                srcset=""
                id="wa"
            />
        </a>
    </div>
    <div class="container">
        <router-view></router-view>
    </div>
    <br />
    <br />
    <div>
        <pie-de-pagina />
    </div>
</template>

<script>
import PieDePagina from "./components/PieDePagina.vue";
import { useStore } from "vuex";
import { computed, reactive } from "vue";
//import { useHead } from "@vueuse/head";

export default {
    data() {
        return {};
    },
    setup() {
        const siteData = reactive({
            title: "CAP WASH MX",
            description: "Cap Wash México, Se lava ajeno!. Servicios de Lavado, Planchado y Restaurado de Gorras en México con CAPWASHMX.",
            keywords: "servicio,de,lavado,planchado,restaurado,de,gorras,maquina,de,vapor,capwashmx,se,lava,ajeno,cap,wash,mx",
        });

        /*useHead({
            title: computed(() => siteData.title),
            meta: [
                {
                    name: `description`,
                    content: computed(() => siteData.description),
                },
                {
                    name: "keywords",
                    content: computed(() => siteData.keywords),
                },
            ],
            link: [
                {
                    rel: 'icon',
                    href: '/logo.png',
                    type: 'image/png'
                }
            ]
        });*/

        const store = useStore();

        const count = computed(() => store.getters.cartItemCount);

        return { count, siteData };
    },
    components: { PieDePagina },
    methods: {
        myFunction() {
            var x = document.getElementById("myTopnav");
            if (x.className === "topnav") {
                x.className += " responsive";
            } else {
                x.className = "topnav";
            }
        },
    },
};
</script>

<style scoped>
.topnav {
    overflow: hidden;
    background-color: #333;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.active {
    background-color: #555;
    color: white;
}

.topnav .icon {
    display: none;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    font-size: 17px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
    background-color: #555;
    color: white;
}

.dropdown-content a:hover {
    background-color: #ddd;
    color: black;
}

.wa {
    width: 6rem;
    position: fixed;
    right: 0;
    top: 85%;
    z-index: 1;
    bottom: 0;
}

.wa-scale {
    animation-name: scale;
    animation-duration: 0.3s;
    animation-delay: 1s;
}

#cart{

    font-size: 22px;
    padding: 8px;
    border-radius: 10px;

}
#cart:hover{
    background-color: #555;
    text-decoration: none;
    font-size: 22px;
    padding: 8px;
    border-radius: 10px;
}

@keyframes scale {
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1.2);
    }
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child),
    .dropdown .dropbtn {
        display: none;
    }
    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }
    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
    .topnav.responsive .dropdown {
        float: none;
    }
    .topnav.responsive .dropdown-content {
        position: relative;
    }
    .topnav.responsive .dropdown .dropbtn {
        display: block;
        width: 100%;
        text-align: left;
    }
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px;
}
.logo {
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
}
</style>

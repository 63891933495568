<template>
    <div>
        <div class="text-center">
            <h2>CAPWASHMX</h2>
            <b><i>...Se lava ajeno!</i></b>
        </div>
        <br />
        <p class="">
            Servicios de Lavado, Planchado y Restaurado de Gorras en México con
            CAPWASHMX.
        </p>
        <div class="p-50">
            <carrusel />
        </div>
        <hr />
        <services/>
        <hr>
            <img class="w-100" src="../img/galery_caps.jpeg" alt="Lavado, planchado y pintado de gorras, restauramos gorras">
        <hr>
        <div class="row">
            
            <div class="col-md-4">
                <img
                    style="width: 100%"
                    src="../img/costos.jpeg"
                    alt="Maquina restauradora de Gorras. La unica Coca fuera del refri"
                />
            </div>
            <div class="col-md-4">
                <img
                    style="width: 100%"
                    src="../img/maquina.jpeg"
                    alt="Maquina restauradora de Gorras. La unica Coca fuera del refri"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Carrusel from "./Carrusel.vue";
import Services from "./Services.vue";
export default {
    components: {
        Services,
        Carrusel,
    },
};
</script>

<style scoped>
p {
    text-align: justify;
}
h1 {
    font-size: 7vmin;
}
</style>

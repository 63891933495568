<template>
    <div>
        <h2>Terminos y Condiciones de Uso</h2>
        <p>
            Estimado Usuario/Cliente,
        </p>
        <p>
            Te informamos que estamos trabajando en una actualización de nuestros Términos y Condiciones para reflejar mejor tus derechos y responsabilidades al utilizar nuestros servicios. Pronto lanzaremos la versión actualizada y te notificaremos para que revises detenidamente estos términos. Tu aceptación de las actualizaciones se considerará con el uso continuado de nuestros servicios. Agradecemos tu confianza en CAPWASHMX y estamos comprometidos a proporcionarte la mejor experiencia posible.
        </p>
        <p>
            ¡Gracias por ser parte de nuestra comunidad!
        </p>
    </div>
</template>

<style scoped>
p{
    text-align: justify;
}
</style>
 

var services = [
    {
        id:1,
        name:"Planchado",
        img:"/img/antes-despues5.ed3f09da.jpeg",
        alt:"Planchado de Gorras",
        price:60,
        description:["Planchado","",""]
    },
    {
        id:2,
        name:"Lavado y Planchado",
        img:"/img/antes-despues.d3160fc5.jpeg",
        alt:"Lavado y Planchado de Gorras",
        price:120,
        description:["Lavado","Planchado",""]
        
    },
    {
        id:3,
        name:"Restaurado",
        img:"/img/antes-despues2.0a4888e4.jpeg",
        alt:"Restauracion de Gorras",
        price:160,
        description:["Lavado","Planchado", "Pintura (Negro,Rojo y Azúl)"]
        
    },

]

export default services

<template>
    <div>
        <h1>Agendar Cita por WhatsApp</h1>
        <p>
            Selecciona Fecha y Hora en la que deseas tener una cita con nosotros para atenderte.
        </p>
        <div class="row" v-if="cartItemCount != 0"  >
            <div class="col-md-7 card">
                <form
                    action=""
                    method="post"
                    class="p-5"
                    v-on:submit.prevent="submit"
                >
                    <div class="row">
                        <div class="form-group p-2 col-md-6">
                            <label for="time">Selecciona Fecha y Hora</label>
                            <input
                                class=""
                                type="datetime-local"
                                name="time"
                                id="time"
                                v-model="date"
                                required
                            />
                        </div>
                    </div>
                    <h5>
                        Cita: <b>{{ getDate(date) }}</b>
                    </h5>
                    <br />
                    <button type="submit" class="btn btn-primary">
                        Enviar 
                    </button>
                </form>
            </div>
            <div class="col-md-5">
                <cart style="width:100%"/>
            </div>
        </div>
        <div v-else>
            <h3 class="text-primary">Ups!</h3>
            <div class="bg-primary text-white p-3 mb-2">
                <p><b>No hay Servicios Encontrados</b></p>
                <p>Agrega un Servicio para continuar con tu Pedido.</p>
            </div>
            <div class="text-center">
                <router-link class="btn btn-secondary" to="/servicios">
                    Agregar Servicios
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Cart from "./Cart.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";

export default {
    data() {
        return {
            wa_msg: "",
            date: "",
            order: "",
        };
    },
    components: {
        Cart,
    },
    setup() {
        const store = useStore();

        const cartItems = computed(() => store.state.cartItems);
        const cartTotalPrice = computed(() => store.getters.cartTotalPrice);
        const cartItemCount = computed(() => store.getters.cartItemCount);

        const removeFromCart = (itemId) => {
            store.commit("removeFromCart", itemId);
        };

        return { cartItems, cartTotalPrice, cartItemCount, removeFromCart };
    },
    methods: {
        toMsg() {
            if (this.date != "") {
                moment.locale("es");

                this.cartItems.forEach((e) => {
                    this.order += "[" + e.name + "-$" + e.price + "]%0A";
                });

                this.wa_msg =
                    "https://wa.me/525533138568?text=Hola! nos podemos ver en Algun Lado?%0A%0A" +
                    " Cita: " +
                    moment(this.date).format("LL, h:mm a") +
                    "%0A%0AServicio(s):%0A%0A" +
                    this.order +
                    "%0A%0A Total: $" +
                    this.cartTotalPrice;

                return this.wa_msg;
            }
        },

        getDate(date) {
            moment.locale("es");
            return moment(date).format("LL, h:mm a");
        },
        submit() {
            window.location.href = this.toMsg();
        },
    },
};
</script>

<style scoped>
h1 {
    font-size: 7vmin;
}
</style>



import Home from './components/Home.vue'
import Contact from './components/Contact.vue'
import About from './components/About.vue'
import Services from './components/Services.vue'
import AgendarCita from './components/AgendarCita.vue'
import Terminos from './components/TerminosCondiciones.vue'


const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path:'/acerca_de',
        component: About,
    },
    {
        path:'/contacto',
        component: Contact,
    },
    {
        path: '/servicios',
        component: Services,
    },
    {
        path:'/agendar_cita',
        component: AgendarCita,
    },
    {
        path: '/terminos',
        component: Terminos,
    },

]


export default routes
// store.js
import { createStore } from "vuex";

export default createStore({
    state: {
        cartItems: [],
    },
    mutations: {
        addToCart(state, item) {
            
                state.cartItems.push(item);
                
        },
        removeFromCart(state, itemId) {
            state.cartItems = state.cartItems.filter(
                (item) => item !== itemId
            );
        },
    },
    getters: {
        cartItemCount: (state) => state.cartItems.length,
        cartTotalPrice: (state) => state.cartItems.reduce((total, item) => total + item.price, 0),
        isInCart: (state) => (itemId) => {
            return state.cartItems.some(item => item.id === itemId);
          }
    },
});

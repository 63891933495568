<template>
    <div>
        <h1>Acerca de</h1>
        <p>
            <b
                >Servicios de Lavado, Planchado y Restaurado de Gorras en México
                con CAPWASHMX</b
            >

        </p>
        <div class="text-center">
            <img src="../img/logo_v2.jpg" class="wdt" alt="" srcset="">
        </div>
        <br>
        <p>
            ¡Bienvenido a CAPWASHMX, tu destino confiable para el cuidado
            completo de gorras en México! Nos enorgullece ofrecer una gama de
            servicios especializados que aseguran que tus gorras favoritas
            luzcan impecables y frescas en todo momento.
        </p>
        <p>
            <b>Lavado de Gorras.</b><br />
            Nuestro servicio de lavado utiliza técnicas avanzadas y productos de
            alta calidad para eliminar manchas, olores y restaurar la frescura
            original de tus gorras. Cuidamos cada detalle para preservar la
            forma y los colores, garantizando un resultado impecable.
        </p>
        <p>
            <b>Planchado.</b><br />
            En CAPWASHMX, entendemos la importancia de que tus gorras luzcan
            perfectamente planchadas. Nuestro servicio de planchado profesional
            utiliza técnicas especializadas para mantener la forma y suavidad de
            tus gorras, proporcionando un acabado impecable y pulcro.
        </p>
        <p>
            <b>Restaurado con Máquina de Vapor.</b><br />
            Destacamos nuestro exclusivo servicio de restaurado de gorras con
            maquina de vapor. Este proceso delicado utiliza vapor de alta
            temperatura para eliminar arrugas, revitalizar tejidos y
            desinfectar, preservando la integridad de tus gorras sin comprometer
            la calidad.
        </p>
        <div class="p-2 text-center">
            <img src="../img/maquina.jpeg" class="w-75" alt="" srcset="">
        </div>
        <p><b>¿Por qué elegir CAPWASHMX?</b></p>
            <ul>
                <li>Experiencia: Contamos con un equipo capacitado en el cuidado especializado de gorras.</li>
                <li>Tecnología Avanzada: Utilizamos equipos modernos y métodos innovadores para garantizar resultados de primera categoría.</li>
                <li>Compromiso con la Calidad: Nos esforzamos por superar tus expectativas, brindando servicios que prolongan la vida útil y la apariencia de tus gorras.</li>
            </ul>
        <p>Confía en CAPWASHMX para mantener tus gorras en su mejor estado. ¡Descubre la diferencia en cada detalle!</p>
        <div class="text-center">
            <img src="../img/wa_qr.jpeg" alt="" class="w-75">
        </div>
    </div>
</template>

<style scoped>
p {
    text-align: justify;
}
.wdt{
    width: 30%;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10%;
}
</style>
